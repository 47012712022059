export default {
  ST006: "disable",
  DB001: "disable",
  TK001: "disable",
  TK002: "disable",
  TK003: "disable",
  TK004: "disable",
  TK005: "disable",
  STK001: "disable",
  STK002: "disable",
  STK003: "disable",
  STK004: "disable",
  STK005: "disable",
  AC001: "disable",
  "AC001-1": "disable",
  STK006: "disable",
  AC002: "disable",
  RP001: "disable",
  RP002: "disable",
  RP003: "disable",
  RP004: "disable",
  RP005: "disable",
  RP006: "disable",
  ST001: "disable",
  ST002: "disable",
  ST003: "disable",
  "ST003-1": "disable",
  ST004: "disable",
  ST005: "disable",
  "ST005-1": "disable",
  ST007: "disable",
  ST008: "disable",
};


