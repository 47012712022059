<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>จัดการกลุ่มสิทธิ์</b></h3>
        <small>
          จัดการกลุ่มสิทธิ์ >
          <b>{{ roleData.roleId ? "แก้ไขกลุ่มสิทธิ์" : "เพิ่มกลุ่มสิทธิ์" }}</b>
        </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          v-if="rolePermission == 'create' || rolePermission == 'edit'"
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="save"
          :disabled="isLoading"
        >
          <b-spinner v-if="isLoading" class="align-middle" small></b-spinner>
          <span v-else>
            <feather-icon icon="SaveIcon" />
            บันทึก
          </span>
        </b-button>

        <b-button
          variant="outline-dark"
          size="sm"
          @click="$router.push('/setting/role')"
          style="height: 35px"
        >
          <feather-icon icon="ArrowLeftIcon" />
          ย้อนกลับ
        </b-button>
      </b-col>
    </b-row>
    <!--- Navigator-->
    <b-overlay
      :show="isLoading"
      rounded="lg"
      opacity="0.55"
      blur="2px"
      variant="transparent"
      fixed
    >
      <b-row class="mt-1">
        <b-col cols="12">
          <!-- START SECTION FORM วันเดินทาง--->
          <b-card>
            <b-row>
              <b-col
                ><h4><b>ข้อมูลสิทธิ์</b></h4></b-col
              >
            </b-row>
            <!--- START (ชื่อกลุ่มสิทธิ์) --->
            <b-row class="mt-1">
              <b-col md="4" class="">
                <label
                  >ชื่อกลุ่มสิทธิ์ <span class="text-danger">*</span></label
                >
                <b-form-input
                  placeholder="ผู้ดูแลระบบ"
                  size="sm"
                  v-model="roleData.roleName"
                />
              </b-col>

              <b-col cols="4" class="">
                <label>สถานะ</label>
                <b-form-group>
                  <b-form-radio-group
                    v-model="statusSelected"
                    :options="statusOptions"
                    name="radio-inline"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!--- END (ชื่อกลุ่มสิทธิ์) --->

            <!--- START ROW รายละเอียด --->
            <b-row class="mt-1">
              <b-col md="12">
                <label>รายละเอียด</label>
                <b-form-textarea
                  id="textarea-default"
                  placeholder="รายละเอียด"
                  rows="2"
                  size="sm"
                  v-model="roleData.roleDescription"
                />
              </b-col>
            </b-row>
            <!--- END รายละเอียด --->

            <b-row class="mt-2 mb-1">
              <b-col
                ><h4><b>Roles</b></h4></b-col
              >
            </b-row>

            <b-row v-for="(role, index) in roles" :key="role.name" class="mt-1">
              <b-col>
                <b>{{ index + 1 }}. {{ role.module }}</b>

                <b-row
                  v-for="menu in role.menus"
                  :key="menu.name"
                  class="ml-1 mt-1"
                >
                  <b-col cols="3">
                    <b class="text-primary">{{ menu.name }}</b>

                    <b-row
                      v-for="permission in menu.permissions"
                      :key="permission"
                      class="ml-1 bg-light"
                      style="margin-bottom: 5px"
                    >
                      <b-col cols="6">{{ uperFirstText(permission) }}</b-col>
                      <b-col cols="6" class="d-flex justify-content-end">
                        <b-form-radio
                          v-model="permissionHolder[menu.code]"
                          :value="permission"
                        ></b-form-radio>
                      </b-col>
                    </b-row>
                  </b-col>

                  <!--- START (ลูกค้า) --->
                  <b-col
                    v-if="
                      menu.code == 'ST005-1' &&
                      permissionHolder[menu.code] == 'enable'
                    "
                    cols="12"
                    class="ml-1 my-1"
                  >
                    <b-row>
                      <b-col cols="3">
                        <label>ลูกค้า</label>
                        <v-select
                          id="brand"
                          v-model="ownerSelected"
                          label="ownerName"
                          :options="ownerOption"
                          class="select-size-sm"
                          :clearable="false"
                          @input="onChangeCustomerOwner"
                        />
                      </b-col>
                      <b-col cols="3" v-if="ownerSelected.id !== 0">
                        <label>Brand</label>
                        <v-select
                          id="brand"
                          v-model="companySelected"
                          label="companyName"
                          :options="companyOption"
                          class="select-size-sm"
                          :clearable="false"
                          @input="onChangeCustomerCompany"
                        />
                      </b-col>

                      <b-col
                        cols="3"
                        v-if="
                          ownerSelected.id !== 0 && companySelected.id !== 0
                        "
                      >
                        <label>บริษัท</label>
                        <v-select
                          id="brand"
                          v-model="subCompanySelected"
                          label="subCompanyName"
                          :options="subCompanyOption"
                          class="select-size-sm"
                          :clearable="false"
                          @input="onChangeCustomerSubCompany"
                        />
                      </b-col>
                    </b-row>

                    <b-row class="mt-1">
                      <b-col>
                        <b-form-checkbox value="1" v-model="selectEngineerMode">
                          <small><b>เปิดให้แก้ไข ผู้เปิด/รับ ใบงาน</b></small>
                        </b-form-checkbox>
                      </b-col>
                    </b-row>                    
                  </b-col>
                  <!--- END (ลูกค้า) --->
                </b-row>
              </b-col>
            </b-row>
          </b-card>
          <!-- END SECTION FORM วันเดินทาง--->
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BTable,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BPagination,
  BTableSimple,
  BForm,
  BTr,
  BTh,
  BTd,
  BThead,
  BTbody,
  BFormRadio,
  BFormRadioGroup,
  BOverlay,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import RoleService from "@/services/setting/role";
import RoleInit from "./data";

import CustomerService from "@/services/setting/customer";

import Roles from "@/share/permissionMenu.config";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BForm,
    BInputGroup,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormDatepicker,
    BPagination,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BThead,
    BTbody,
    BFormRadio,
    BFormRadioGroup,
    BOverlay,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      statusSelected: 1,
      statusOptions: [
        { text: "เปิด", value: 1 },
        { text: "ปิด", value: 0 },
      ],
      roles: Roles,
      roleData: {},
      permissionHolder: RoleInit,
      isLoading: false,
      ownerSelected: { ownerName: "เลือกทั้งหมด", id: 0 },
      ownerOption: [],
      companySelected: {},
      companyOption: [],
      subCompanySelected: {},
      subCompanyOption: [],
      roleOption: {},
      selectEngineerMode:"0",
      mode: "create",
    };
  },

  computed: {
    rolePermission() {
      const permission = this.$store.getters["user/getPermissionPage"]("ST004");
      if (permission == "disable") {
        localStorage.removeItem("userData");
        window.location.href = "/not-authorized";
      }
      return permission;
    },
  },

  watch: {
    roleData(newValue, oldValue) {
      if (newValue.roleId) {
        this.mode = "edit";
      } else {
        this.mode = "create";
      }
    },
  },

  created() {
    const paramRoleId = this.$route.params.roleId;
    if (paramRoleId) {
      this.getRoleById(paramRoleId);
    }

   
  },
  methods: {
    async getRoleById(paramRoleId) {
      const result = await RoleService.GetRoleById(paramRoleId);
      this.roleData = result.data;
      this.statusSelected = result.data.roleStatus;
      
      this.roleOption = this.findPermissionCodeFromOption(
        "ST005-1",
        result.data.options || []
      );

      this.selectEngineerMode = this.roleOption.selectEngineerMode || 0
      if (result.data.permission.length > 0) {
        this.permissionHolder = result.data.permission[0];
      }

       this.getCustomerOwner();
    },

    async save() {
      if (!this.roleData.roleName) {
        alert("กรุณากรอกชื่อกลุ่มสิทธิ์");
        return;
      }

      if (this.roleData.roleId) {
        await this.update();
      } else {
        await this.insert();
      }
    },

    async insert() {
      this.isLoading = true;
      const options = [
        {
          permissionCode: "ST005-1",
          ownerId: this.ownerSelected.id || 0,
          companyId: this.companySelected.id || 0,
          subCompanyId: this.subCompanySelected.id || 0,
          selectEngineerMode:this.selectEngineerMode || "0"
        },
      ];
      const obj = {
        ...this.roleData,
        permission: JSON.stringify([this.permissionHolder]),
        roleStatus: this.statusSelected,
        options: JSON.stringify(options),
      };

      const result = await RoleService.InsertRole(obj);
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Role`,
            icon: "CheckIcon",
            variant: "success",
            text: `เพิ่มรายการเรียบร้อย`,
          },
        });
        this.$router.push("/setting/role");
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Role`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเพิ่มรายการ`,
          },
        });
      }

      this.isLoading = false;
    },

    async update() {
      this.isLoading = true;
      const options = [
        {
          permissionCode: "ST005-1",
          ownerId: this.ownerSelected.id || 0,
          companyId: this.companySelected.id || 0,
          subCompanyId: this.subCompanySelected.id || 0,
          selectEngineerMode:this.selectEngineerMode || "0"
        },
      ];

      const obj = {
        ...this.roleData,
        permission: JSON.stringify([this.permissionHolder]),
        roleStatus: this.statusSelected,
        options: JSON.stringify(options),
      };

      const result = await RoleService.UpdateRole(obj, obj.roleId);
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Role`,
            icon: "CheckIcon",
            variant: "success",
            text: `เพิ่มรายการเรียบร้อย`,
          },
        });
        this.$router.push("/setting/role");
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Role`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเพิ่มรายการ`,
          },
        });
      }

      this.isLoading = false;
    },
    uperFirstText(word) {
      const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
      return capitalized;
    },
    findPermissionCodeFromOption(permissionCode, lists) {
      const finded = lists.find((f) => f.permissionCode == permissionCode);
      return finded;
    },
    async getCustomerOwner() {
      const result = await CustomerService.GetCustomerOwnerList({});
      this.ownerOption.push({ ownerName: "เลือกทั้งหมด", id: 0 });
      this.ownerOption = [...this.ownerOption, ...result.data.data];
      if (result.data.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = ownerOption[0];
        } else {
          item = this.ownerOption.find((f) => f.id == this.roleOption.ownerId);

          if (!item) {
            item = ownerOption[0];
          }
        }

        this.ownerSelected = item;
        this.onChangeCustomerOwner(item);
      } else {
        this.ownerSelected = {};
      }
    },
    async getCustomerCompany(ownerObj) {
      const result = await CustomerService.GetCustomerCompanyList(ownerObj.id);
      this.companyOption = [
        { id: 0, companyName: "==== ทั้งหมด ====" },
        ...result.data.data,
      ];
      if (result.data.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = this.companyOption[0];
        } else {
          item = this.companyOption.find(
            (f) => f.id == this.roleOption.companyId
          );

          if (!item) {
            item = this.companyOption[0];
          }
        }

        this.companySelected = item;
        this.onChangeCustomerCompany(item);
      } else {
        this.companySelected = {};
      }
    },

    async getCustomerSubCompany(companyObj) {
      const result = await CustomerService.GetCustomerSubCompanyList(
        companyObj.id
      );

      this.subCompanyOption = [
        { id: 0, subCompanyName: "==== ทั้งหมด ====" },
        ...result.data.data,
      ];
      if (result.data.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = this.subCompanyOption[0];
        } else {
          item = this.subCompanyOption.find(
            (f) => f.id == this.roleOption.subCompanyId
          );

          if (!item) {
            item = this.subCompanyOption[0];
          }
        }

        this.subCompanySelected = item;
        this.onChangeCustomerSubCompany(item);
      } else {
        this.subCompanySelected = {};
      }
    },

    onChangeCustomerOwner(item) {
      this.getCustomerCompany(item);
    },
    onChangeCustomerCompany(item) {
      this.getCustomerSubCompany(item);
    },
    onChangeCustomerSubCompany(item) {
      console.log("sub company selected");
    },
  },
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
