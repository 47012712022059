export default [
  //DASHBOARD
  {
    module: "Dashboard",
    menus: [
      { code: "DB001", name: "Dashboard", permissions: ["disable", "view"] },
    ],
  },
  //TICKET
  {
    module: "Ticket",
    menus: [
      {
        code: "TK001",
        name: "Ticket",
        permissions: ["disable", "view", "create", "edit"],
      },
      {
        code: "TK002",
        name: "Export Ticket (Inprogress)",
        permissions: ["disable", "view"],
      },
      {
        code: "TK003",
        name: "Closed Tickets",
        permissions: ["disable", "view", "edit"],
      },
      {
        code: "TK004",
        name: "Summary Tickets Report",
        permissions: ["disable", "view"],
      },
      {
        code: "TK005",
        name: "Cancel Tickets",
        permissions: ["disable", "view", "edit"],
      },
    ],
  },
  //STOCK
  {
    module: "Stock",
    menus: [
      {
        code: "STK001",
        name: "Vserv Stock",
        permissions: ["disable", "view", "create", "edit"],
      },
      {
        code: "STK002",
        name: "Repair",
        permissions: ["disable", "view", "create", "edit"],
      },
      {
        code: "STK003",
        name: "Onhand",
        permissions: ["disable", "view", "edit"],
      },
      {
        code: "STK004",
        name: "Warranty",
        permissions: ["disable", "view", "create", "edit"],
      },
      { code: "STK005", name: "Log Stock", permissions: ["disable", "view"] },
      { code: "STK006", name: "Log Repair", permissions: ["disable", "view"] },
    ],
  },
  //ACCOUNTING
  {
    module: "Accounting",
    menus: [
      {
        code: "AC001",
        name: "Accounting",
        permissions: ["disable", "view", "create", "edit"],
      },
      {
        code: "AC001-1",
        name: "View Dropdown All User For Search",
        permissions: ["disable", "view"],
      },
      {
        code: "AC002",
        name: "Expenses Report by Employee",
        permissions: ["disable", "view", "edit"],
      },
    ],
  },
  //REPORT
  {
    module: "Reports",
    menus: [
      {
        code: "RP001",
        name: "Ticket",
        isHighlight: true,
        permissions: ["disable", "view"],
      },
      {
        code: "RP002",
        name: "Export Ticket By Customer",
        permissions: ["disable", "all", "someone"],
      },
      {
        code: "RP003",
        name: "Export Ticket By Employee",
        permissions: ["disable", "all", "someone"],
      },
      {
        code: "RP004",
        name: "Accounting",
        isHighlight: true,
        permissions: ["disable", "view"],
      },
      {
        code: "RP005",
        name: "Export Accounting By Customer",
        permissions: ["disable", "all", "someone"],
      },
      {
        code: "RP006",
        name: "Export Accounting By Employee",
        permissions: ["disable", "all", "someone"],
      },
    ],
  },
  //SETTING
  {
    module: "Setting",
    menus: [
      {
        code: "ST001",
        name: "Hardware setup (stock)",
        permissions: ["disable", "view", "create", "edit"],
      },
      {
        code: "ST002",
        name: "Hardware setup (warranty)",
        permissions: ["disable", "view", "create", "edit"],
      },
      {
        code: "ST003",
        name: "Customer setup (Stock)",
        permissions: ["disable", "view", "create", "edit"],
      },
      {
        code: "ST003-1",
        name: "Customer setup (Warranty)",
        permissions: ["disable", "view", "create", "edit"],
      },
      {
        code: "ST004",
        name: "Role and permission",
        permissions: ["disable", "view", "edit"],
      },
      {
        code: "ST005",
        name: "จัดการ User",
        permissions: ["disable", "view", "create", "edit"],
      },
      {
        code: "ST005-1",
        name: "กำหนดให้ User เป็นประเภท Customer",
        permissions: ["disable", "enable"],
      },
      {
        code: "ST006",
        name: "ตั้งค่าระยะทางลูกค้า",
        permissions: ["disable", "view", "edit"],
      },
      {
        code: "ST007",
        name: "ตั้งค่าระยะทางพนักงาน",
        permissions: ["disable", "view", "edit"],
      },
      {
        code: "ST008",
        name: "ตั้งค่า Email",
        permissions: ["disable", "view", "edit"],
      },
    ],
  },
];
